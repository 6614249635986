// components/SelectField.tsx
import React from 'react';
import { FormControl, Typography, InputLabel, Select, MenuItem, Grid } from '@material-ui/core';

interface SelectFieldProps {
  label: string;
  placeHolder: string;
  name: string;
  value: string;
  onChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
  options: { value: string; label: string }[];
  gridSizes: { xs: boolean | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12; sm: boolean | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 };
  disabled?: boolean;
}

const SelectField: React.FC<SelectFieldProps> = ({ label, placeHolder, name, value, onChange, options, gridSizes, disabled = false }) => (
  <Grid item {...gridSizes}>
    <Typography>{label}</Typography>
    <FormControl fullWidth>
      <InputLabel>{placeHolder}</InputLabel>
      <Select
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Grid>
);

export default SelectField;
