//export const TG_CLIENT_HOST = process.env.CLIENT_WEB_URL
//export const TG_CLIENT_HOST = 'http://3.36.209.168'
export const TG_CLIENT_HOST = 'https://www.kistor.kr'

const normalizedUrl = TG_CLIENT_HOST.replace(/\/+$/, '');

export const TGswitchLink = (keyword) => {  
  let link = ''
  
  switch(keyword) {
    case 'APPROVE': // 입점승인 테스트 페이지
      link = normalizedUrl + '/vendor/10'
      break
    case 'FIND_ID': // 아이디 찾기 페이지
      link = normalizedUrl + '/login/findid'
      break
    case 'REGISTER': // 회원가입 페이지
      link = normalizedUrl + '/vendor/1'
      break
    case 'ENTRY_APPLY': // 입점신청 페이지
      link = normalizedUrl + '/vendor/3'
      break
    default:
      link = normalizedUrl + '/'
    
  }
  window.open(link, '_blank');
}

export const TGswitchLabel = (keyword) => {
  
  switch(keyword) {
    case 'hotdeal': 
      return '핫딜'
    case 'oversea': 
      return '해외직구'
    case 'home': 
      return '홈'
    case 'coupon': 
      return '쿠폰/혜택'
    case 'brand': 
      return '브랜드'
    case 'biz': 
      return 'BIZ'  
    default:
      return '수정필요(관리자 문의)'
    
  }
}