import { gql } from "@apollo/client";
import * as Types from "@dashboard/graphql/types.generated";
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@dashboard/hooks/graphql';
import { ExportProductsToShopifyInput } from "@dashboard/products/views/ProductList/export";
import { ExportErrorCode, JobStatusEnum } from "@saleor/sdk/dist/apollo/types";
import { ProductChannelListingErrorFragmentDoc } from "@dashboard/graphql";

export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };

const defaultOptions = {} as const;

export type ExportToShopifyErrorFragment = { __typename: 'ExportToShopifyError', code: ExportErrorCode, field: string | null, message: string | null };

export type ProductExportToShopifyMutation = {
  __typename: 'Mutation', exportProductsToShopify: { __typename: 'ExportProductsToShopify', exportFile: { __typename: 'ExportFileToShopify', id: string, status: JobStatusEnum, url: string | null } | null, errors: Array<{ __typename: 'ExportError', code: ExportErrorCode, field: string | null, message: string | null }> } | null
};

export type ProductExportToShopifyMutationVariables = Exact<{
  input: ExportProductsToShopifyInput;
}>;

export const ExportFileFragmentDoc = gql`
    fragment ExportFile on ExportFile {
  id
  status
  url
}
    `;
export const ExportErrorFragmentDoc = gql`
    fragment ExportError on ExportError {
  code
  field
  message
}
    `;
export const ProductExportToShopifyDocument = gql`
    mutation ProductExportToShopify($input: ExportProductsToShopifyInput!) {
  exportProductsToShopify(input: $input) {
    exportFile {
      ...ExportFile
    }
    errors {
      ...ExportError
    }
  }
}
    ${ExportFileFragmentDoc}
${ExportErrorFragmentDoc}`;


export function useProductExportToShopifyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ProductExportToShopifyMutation, ProductExportToShopifyMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<ProductExportToShopifyMutation, ProductExportToShopifyMutationVariables>(ProductExportToShopifyDocument, options);
}
export type ProductExportToShopifyMutationHookResult = ReturnType<typeof useProductExportToShopifyMutation>;
export type ProductExportToShopifyMutationResult = Apollo.MutationResult<ProductExportToShopifyMutation>;
export type ProductExportToShopifyMutationOptions = Apollo.BaseMutationOptions<ProductExportToShopifyMutation, ProductExportToShopifyMutationVariables>;