// @ts-strict-ignore
import { createContext, useContext } from "react";
import { TopNav } from "@dashboard/components/AppLayout/TopNav";
import { DashboardCard } from "@dashboard/components/Card";
import { ConfirmButtonTransitionState } from "@dashboard/components/ConfirmButton";
import { DetailPageLayout } from "@dashboard/components/Layouts";
import { formatMoneyAmount } from "@dashboard/components/Money";
import PriceField from "@dashboard/components/PriceField";
import Savebar from "@dashboard/components/Savebar";
import {
  OrderDetailsGrantedRefundFragment,
  OrderDetailsGrantRefundFragment,
} from "@dashboard/graphql";
import useLocale from "@dashboard/hooks/useLocale";
import useNavigator from "@dashboard/hooks/useNavigator";
import { orderUrl } from "@dashboard/orders/urls";
import { Box, Input, Skeleton, Text, Checkbox } from "@saleor/macaw-ui-next";
import React, { useState, useEffect, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { getOrderTitleMessage } from "../OrderCardTitle/utils";
import { ProductsCard } from "./components/ProductCard";
import { ShippingIncluded } from "./components/ShippingInluded";
import { GrantRefundContext } from "./context";
import { OrderGrantRefundFormData, useGrantRefundForm } from "./form";
import { grantRefundPageMessages } from "./messages";
import {
  getGrantRefundReducerInitialState,
  grantRefundDefaultState,
  grantRefundReducer,
} from "./reducer";
import {
  calculateCanRefundShipping,
  calculateTotalPrice,
  getFulfilmentSubtitle,
  getGrantedRefundData,
  getRefundAmountValue,
  prepareLineData,
} from "./utils";
import api from '@dashboard/stores/services/REST_apis/client';

export interface OrderGrantRefundPageProps {
  order: OrderDetailsGrantRefundFragment;
  loading: boolean;
  submitState: ConfirmButtonTransitionState;
  onSubmit: (data: OrderGrantRefundFormData) => void;
  isEdit?: boolean;
  initialData?: OrderDetailsGrantedRefundFragment;
}

const OrderGrantRefundPage: React.FC<OrderGrantRefundPageProps> = ({
  order,
  loading,
  submitState,
  onSubmit,
  isEdit,
  initialData,
}) => {
  const intl = useIntl();
  const { locale } = useLocale();
  const navigate = useNavigator();

  const grantedRefund = useMemo(
    () => getGrantedRefundData(initialData),
    [initialData],
  );

  const unfulfilledLines = (order?.lines ?? []).filter(
    line => line.quantityToFulfill > 0,
  );
  const [state, dispatch] = React.useReducer(
    grantRefundReducer,
    grantRefundDefaultState,
  );

  useEffect(() => {
    if (grantedRefund) {
      dispatch({
        type: "setRefundShipping",
        refundShipping: grantedRefund.grantRefundForShipping,
      });
    }
  }, [grantedRefund]);

  useEffect(() => {
    if (order?.id) {
      dispatch({
        type: "initState",
        state: getGrantRefundReducerInitialState(order, initialData),
      });
    }
  }, [order, initialData]);

  const lines = prepareLineData(state.lines);
  const canRefundShipping = calculateCanRefundShipping(
    grantedRefund,
    order?.grantedRefunds,
  );

  // const basketInfo = useMemo(() => {
  //   return lines.map((line) => {
  //     // order.metadata에서 line id와 key가 일치하는 메타데이터 값을 찾음
  //     const matchingMetadata = order?.metadata?.find((metadataItem) => metadataItem.key === line.id);
  //     const pgCnoMetadata = order?.metadata?.find((metadataItem) => metadataItem.key === 'pgCno');
  //     const shippingMetadata = order?.metadata?.find((metadataItem) => metadataItem.key.startsWith('SHIPPING___'));

  //     // 메타데이터에서 '___'를 기준으로 값들을 분리
  //     const metadataValues = matchingMetadata ? matchingMetadata.value.split('___') : [];
  
  //     return {
  //       pgCno: pgCnoMetadata.value || null,
  //       sellerId: metadataValues[0] || null,
  //       productPgCno: metadataValues[1] || null,
  //       shippingAmount : shippingMetadata?.key.split('___')[1],
  //       shippingPgCno : shippingMetadata?.value.split('___')[1],
  //     };
  //   });
  // }, [lines]);
  
  const basketInfo = useMemo(() => {
    return lines.map((line) => {
      const selectedLine = unfulfilledLines.find((selectedLine)=> selectedLine.id === line.id)
      const sellerId = selectedLine.metadata.find((meta)=> meta.key === 'sellerId')
      const productPgCno = selectedLine.metadata.find((meta)=> meta.key === 'productPgCno')
      const shippingMetadata = order?.metadata.find((meta)=> meta.key === 'shippingInfo');
      
      const pgCnoMetadata = order?.metadata?.find((metadataItem) => metadataItem.key === 'pgCno');

      return {
        unfulfilledLines: unfulfilledLines,
        pgCno: pgCnoMetadata.value || null,
        sellerId: sellerId.value || null,
        productPgCno: productPgCno.value || null,
        shippingAmount : shippingMetadata?.value.split('___')[1] || null,
        shippingPgCno : shippingMetadata?.value.split('___')[2] || null,
      };
    });
  }, [lines]);

  useEffect(() => {
    if(basketInfo.length == 0) setShippingAmount(0)
    console.log('lines', lines);
    console.log('order', order);
    console.log('basketInfo', basketInfo);
    // console.log('basketInfo2', basketInfo2);
    console.log('data.reason', data);
    console.log('total Amount', getRefundAmountDisplayValue());
    console.log('Real total Amount', Number(getRefundAmountDisplayValue())-shippingAmount);
    console.log('Real total Amount', (Number(getRefundAmountDisplayValue())-shippingAmount) * 1351.35);
  }, [basketInfo]);
  
  const toggleShipping = () => {
    if(! shippingAmount) setShippingAmount(Number(basketInfo[0].shippingAmount))
    else setShippingAmount(0)
  }

  const [shippingAmount, setShippingAmount] = useState(0);

  const easyPayOnsubmit = () => {

    basketInfo.map((basket) => { 

      const body = {
        pgCno: basketInfo[0]?.pgCno,
        amount: order.total.gross.currency === 'KRW' ? Number(getRefundAmountDisplayValue())-shippingAmount : (Number(getRefundAmountDisplayValue())-shippingAmount) * 1351.35,
        reviseMessage: data.reason ? data.reason : '-',
        basketInfo: {
          sellerId: basket?.sellerId, 
          productPgCno: basket?.productPgCno
        }
      };

      api.post('/api-tg/pay/easypay/cancel', body, { timeout: 5000 })
      .then((res) => {
        console.log('basket res', res, 'basket body', body);
        if(res.data.resCd==='0000') {
          console.log('0000');

          if(order?.grantedRefunds?.length === 0) submit();
          else {
            alert('정상적으로 환불처리 되었습니다.');
            window.history.back();
          } 

        } else {
          alert(res.data.resMsg);
        }
      }).catch(e => {
        alert('오류가 발생했습니다.\n다시 시도해주세요.');
        console.log(e);
      });

    })

    // 배송료만 따로 한번 더 결제 취소
    if(shippingAmount > 0) {

      const shippingBody = {
        pgCno: basketInfo[0]?.pgCno,
        amount: order.total.gross.currency === 'KRW' ? shippingAmount : shippingAmount * 1351.35,
        reviseMessage: data.reason ? data.reason : '-',
        basketInfo: {
          sellerId: basketInfo[0]?.sellerId, 
          productPgCno: basketInfo[0]?.shippingPgCno
        }
      };

      api.post('/api-tg/pay/easypay/cancel', shippingBody, { timeout: 5000 })  // 5초 타임아웃
      .then((res) => {
        console.log('shipping res', res);
        if(res.data.resCd==='0000') {
          console.log('0000');
        } else {
          alert(res.data.resMsg);
        }
      }).catch(e => {
        alert('오류가 발생했습니다.\n다시 시도해주세요.');
        console.log(e);
      });

    }

  };

  const { set, change, data, submit, setIsDirty, isFormDirty } =
    useGrantRefundForm({
      onSubmit,
      grantedRefund,
      lines,
      // Send grantRefundForShipping only when it's different than the one
      grantRefundForShipping:
        grantedRefund?.grantRefundForShipping === state.refundShipping
          ? undefined
          : state.refundShipping,
    });

  const totalSelectedPrice = calculateTotalPrice(state, order);
  const amountValue = getRefundAmountValue({
    isEditedRefundAmount: grantedRefund !== undefined,
    isAmountInputDirty: isFormDirty.amount,
    refundAmount: Number(data.amount),
    totalCalulatedPrice: totalSelectedPrice,
  });

  const currency = order?.total?.gross?.currency ?? "";

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.stopPropagation();
    e.preventDefault();
    submit();
  };

  const getRefundAmountDisplayValue = () => {
    if (isFormDirty) {
      return (amountValue+shippingAmount).toString();
    }

    return formatMoneyAmount(
      {
        amount: amountValue + shippingAmount,
        currency,
      },
      locale,
    );
  };

  return (
    <DetailPageLayout gridTemplateColumns={1}>
      <TopNav
        href={orderUrl(order?.id)}
        title={
          <FormattedMessage
            {...(isEdit
              ? grantRefundPageMessages.pageHeaderEdit
              : grantRefundPageMessages.pageHeader)}
          />
        }
      ></TopNav>
      <form onSubmit={handleSubmit} style={{ display: "contents" }}>
        <GrantRefundContext.Provider
          value={{
            dispatch: (...args) => {
              setIsDirty(true);
              dispatch(...args);
            },
            state,
            form: { change, data, set },
            totalSelectedPrice,
          }}
        >
          <DetailPageLayout.Content>
            <DashboardCard>
              <DashboardCard.Content
                display="flex"
                flexDirection="column"
                gap={5}
              >
                <Text variant="bodyEmp" as="p" marginTop={5}>
                  <FormattedMessage {...grantRefundPageMessages.pageSubtitle} /> <br/>
                  해당 페이지에서 환불처리할 경우, 이지페이를 통해 실제로 환불처리가 진행됩니다.
                </Text>

                {loading ? (
                  <Skeleton />
                ) : (
                  <>
                    <ProductsCard
                      title={
                        <FormattedMessage
                          {...grantRefundPageMessages.unfulfilledProducts}
                        />
                      }
                      lines={unfulfilledLines}
                    />

                    {order?.fulfillments?.map?.(fulfillment => (
                      <ProductsCard
                        key={fulfillment.id}
                        title={intl.formatMessage(
                          getOrderTitleMessage(fulfillment.status),
                        )}
                        subtitle={
                          <Text
                            variant="body"
                            display="inline-block"
                            marginLeft={1}
                          >
                            {getFulfilmentSubtitle(order, fulfillment)}
                          </Text>
                        }
                        lines={fulfillment.lines.map(
                          ({ orderLine, id, quantity }) => {
                            return {
                              ...orderLine,
                              id,
                              quantity,
                            };
                          },
                        )}
                      />
                    ))}
                  </>
                )}

                <ShippingIncluded
                  currency={currency}
                  amount={order?.shippingPrice?.gross}
                  canRefundShipping={canRefundShipping}
                />

                {/* 배송료 체크박스 */}
                {
                  (basketInfo.length > 0) &&
                  <Box display="flex" alignItems="center" gap={2}>
                    <Checkbox
                      disabled={loading}
                      data-test-id="includeShippingCheckbox"
                      onClick={toggleShipping}
                    />
                    <Text variant="body">
                      배송료 포함
                    </Text>
                  </Box>
                }
                {
                  <div>
                    현재 USD/KRW 환율: : 1$ = 1351.35₩
                  </div>
                }
                <Box display="flex" gap={3}>
                  <Box __flexGrow={2} flexBasis="0">
                    <Input
                      label={intl.formatMessage(
                        grantRefundPageMessages.reasonForRefund,
                      )}
                      disabled={loading}
                      value={data.reason}
                      name={"reason" as keyof OrderGrantRefundFormData}
                      onChange={change}
                      type="text"
                      data-test-id="refundReasonInput"
                    />
                  </Box>
                  <PriceField
                    flexGrow="1"
                    flexBasis="0"
                    label={intl.formatMessage(
                      grantRefundPageMessages.refundAmountLabel,
                    )}
                    onChange={change}
                    disabled={loading}
                    name={"amount" as keyof OrderGrantRefundFormData}
                    currencySymbol={currency}
                    value={getRefundAmountDisplayValue()}
                    data-test-id="amountInput"
                    readOnly
                  />
                </Box>
              </DashboardCard.Content>
            </DashboardCard>
          </DetailPageLayout.Content>
        </GrantRefundContext.Provider>
      </form>
      <Savebar
        labels={{
          confirm: isEdit
            ? intl.formatMessage(grantRefundPageMessages.editRefundBtn)
            : intl.formatMessage(grantRefundPageMessages.grantRefundBtn),
        }}
        onCancel={() => navigate(orderUrl(order?.id))}
        onSubmit={easyPayOnsubmit}
        state={submitState}
        disabled={loading}
      />
    </DetailPageLayout>
  );
};

export default OrderGrantRefundPage;
