// @ts-strict-ignore
import CardTitle from "@dashboard/components/CardTitle";
import FormSpacer from "@dashboard/components/FormSpacer";
import RichTextEditor from "@dashboard/components/RichTextEditor";
import { RichTextEditorLoading } from "@dashboard/components/RichTextEditor/RichTextEditorLoading";
import { PageErrorFragment } from "@dashboard/graphql";
import { commonMessages } from "@dashboard/intl";
import { getFormErrors } from "@dashboard/utils/errors";
import getPageErrorMessage from "@dashboard/utils/errors/page";
import { useRichTextContext } from "@dashboard/utils/richText/context";
import { Card, CardContent, TextField, InputLabel } from "@material-ui/core";
import { makeStyles } from "@saleor/macaw-ui";
import { useIntl } from "react-intl";
import React, { useState } from "react";
import { PageData } from "../PageDetailsPage/form";
import Rating from '@material-ui/lab/Rating'; // 별점 컴포넌트 추가
import { useLocation } from "react-router";

export interface PageInfoProps {
  data: PageData;
  disabled: boolean;
  errors: PageErrorFragment[];
  onChange: (...args: any[]) => any;
  onMetaChange: (...args: any[]) => any;
}

const useStyles = makeStyles(
  {
    root: {
      overflow: "visible",
    },
    imageContainer: {
      display: "flex",
      flexWrap: "wrap", // 이미지를 가로로 배치하고 줄바꿈을 설정
      justifyContent: "flex-start", // 이미지를 왼쪽으로 정렬
      gap: "12px", // 이미지 간격 설정
    },
    imageItem: {
      width: "140px", // 일정한 크기 유지
      height: "140px", 
      objectFit: "cover", // 이미지의 비율을 유지하며 크기에 맞게 조정
      borderRadius: "8px", // 이미지를 둥글게 처리
      border: "1px solid #ddd", // 테두리 추가
      padding: "4px",
      backgroundColor: "#fff", // 이미지 배경색
    },
    // 읽기 전용 텍스트 색상
    readOnlyInput: {
      color: "#757575" , // 회색 톤으로 변경
    },
  },
  { name: "PageInfo" },
);

const PageInfo: React.FC<PageInfoProps> = props => {
  const { data, disabled, errors, onChange, onMetaChange } = props;

  const classes = useStyles(props);
  const intl = useIntl();
  // 페이지 데이터 사용 예시
  const pageTitle = data.page?.title || "제목 없음";
  // console.log(pageTitle);
  // console.log('data.page',data.page);
  // const userData = data.page.writer;
  // console.log('userData',userData);
  const { defaultValue, editorRef, isReadyForMount, handleChange } =
    useRichTextContext();
  const formErrors = getFormErrors(["title", "content"], errors);

  // 불러온 이미지 URL 리스트
  const [imageUrls, setImageUrls] = useState<string[]>([
    "https://via.placeholder.com/150", // 기본 이미지 예시
    "https://via.placeholder.com/150",
  ]);

  // 추가된 상태 변수들
  const [reviewImages, setReviewImages] = useState<FileList | null>(null);
  const location = useLocation(); // 현재 URL 정보를 가져옴
  // console.log('location', location);
  
  return (
    <Card className={classes.root}>
      <CardTitle
        title={intl.formatMessage(commonMessages.generalInformations)}
      />
      <CardContent>
        {/* 콘텐츠 유형 출력 */}
        <TextField
          disabled={disabled}
          fullWidth
          label="콘텐츠 유형"
          name="pageType"
          value={data.pageType?.name || "유형 없음"} // 콘텐츠 유형이 있으면 표시, 없으면 "유형 없음"
          InputProps={{
            readOnly: true, // 읽기 전용 설정
          }}
        />
        <FormSpacer />

        {/* 기존 제목 입력 */}
        <TextField
          disabled={disabled}
          error={!!formErrors.title}
          fullWidth
          helperText={getPageErrorMessage(formErrors.title, intl)}
          label={intl.formatMessage({
            id: "gr+oXW",
            defaultMessage: "Title",
            description: "page title",
          })}
          name={"title" as keyof PageData}
          value={data.title}
          onChange={onChange}
        />
        <FormSpacer />
        {isReadyForMount ? (
          <RichTextEditor
            defaultValue={defaultValue}
            editorRef={editorRef}
            onChange={handleChange}
            disabled={disabled}
            error={!!formErrors.content}
            helperText={getPageErrorMessage(formErrors.content, intl)}
            label={intl.formatMessage({
              id: "gMwpNC",
              defaultMessage: "Content",
              description: "page content",
            })}
            name={"content" as keyof PageData}
          />
        ) : (
          <RichTextEditorLoading
            label={intl.formatMessage({
              id: "gMwpNC",
              defaultMessage: "Content",
              description: "page content",
            })}
            name={"content" as keyof PageData}
          />
        )}

        <FormSpacer />

        {/* 리뷰 페이지 정보 */}
        {data.pageType?.name === "리뷰" && (
          <>
            <TextField
              fullWidth
              label="이름"
              name="name"
              value={data.page?.writer?.firstName || ""} // writer가 없는 경우 대비
              InputProps={{
                readOnly: true, // 읽기 전용 설정
              }}
              disabled={disabled}
            />
            <FormSpacer />
            <TextField
              fullWidth
              label="닉네임"
              name="nickname"
              value={data.page?.writer?.metafields?.nickName || ""} // writer 및 metafields가 없는 경우 대비
              InputProps={{
                readOnly: true, // 읽기 전용 설정
              }}
              disabled={disabled}
            />
            <FormSpacer />
            <TextField
              fullWidth
              label="이메일"
              name="email"
              value={data.page?.writer?.email || ""} // writer가 없는 경우 대비
              InputProps={{
                readOnly: true, // 읽기 전용 설정
              }}
              disabled={disabled}
            />
            <FormSpacer />
            <TextField
              fullWidth
              label="전화번호"
              name="phone"
              value={data.page?.writer?.metafields?.phone || ""} // writer 및 metafields가 없는 경우 대비
              InputProps={{
                readOnly: true, // 읽기 전용 설정
              }}
              disabled={disabled}
            />
            <FormSpacer />
            <TextField
              fullWidth
              label="별점"
              name="rating"
              value={data.page?.metadata?.find(meta => meta.key === "rating")?.value || ""} 
              InputProps={{
                readOnly: true, // 읽기 전용 설정
              }}
              disabled={disabled}
            />

            <FormSpacer />
            <TextField
              fullWidth
              label="등록일자"
              name="createDate"
              value={data.page?.publicationDate || ""} // publicationDate가 없는 경우 대비
              InputProps={{
                readOnly: true, // 읽기 전용 설정
              }}
              disabled={disabled}
            />
            <FormSpacer />
            {/* 리뷰 이미지 */}
            <InputLabel>리뷰 이미지</InputLabel>
            <FormSpacer />
            <div className={classes.imageContainer}>
              {data.page?.media && data.page?.media?.length > 0 ? (
                data.page.media.slice(0, 10).map((mediaItem, index) => (
                  <img
                    key={index}
                    src={mediaItem?.pageImgUrl ?? "https://via.placeholder.com/150"}
                    alt={`Review Image ${index}`}
                    className={classes.imageItem}
                  />
                ))
              ) : (
                <img
                  src="https://via.placeholder.com/150"
                  alt="Default Review Image"
                  className={classes.imageItem}
                />
              )}
            </div>
          </>
        )}

        {/* 문의 페이지 정보*/}
        {data.pageType?.name === "문의" && (
          <>
            <TextField
              fullWidth
              label="이름"
              name="name"
              value={data.page?.writer?.firstName || ""} // writer가 없는 경우 대비
              InputProps={{
                readOnly: true, // 읽기 전용 설정
              }}
              disabled={disabled}
            />
            <FormSpacer />
            <TextField
              fullWidth
              label="닉네임"
              name="nickname"
              value={data.page?.writer?.metafields?.nickName || ""} // writer 및 metafields가 없는 경우 대비
              InputProps={{
                readOnly: true, // 읽기 전용 설정
              }}
              disabled={disabled}
            />
            <FormSpacer />
            <TextField
              fullWidth
              label="이메일"
              name="email"
              value={data.page?.writer?.email || ""} // writer가 없는 경우 대비
              InputProps={{
                readOnly: true, // 읽기 전용 설정
              }}
              disabled={disabled}
            />
            <FormSpacer />
            <TextField
              fullWidth
              label="전화번호"
              name="phone"
              value={data.page?.writer?.metafields?.phone || ""} // writer 및 metafields가 없는 경우 대비
              InputProps={{
                readOnly: true, // 읽기 전용 설정
              }}
              disabled={disabled}
            />
            <FormSpacer />
            <TextField
              fullWidth
              label="답변입력란"
              name="answer"
              value={data.answer || ""} // answer 필드의 상태 값을 사용
              disabled={disabled}
              onChange={onMetaChange} 
              InputProps={{
                name: "answer", 
              }}
            />

            <FormSpacer />
            <TextField
              fullWidth
              label="등록일자"
              name="createDate"
              value={data.page?.publicationDate || ""} // publicationDate가 없는 경우 대비
              InputProps={{
                readOnly: true, // 읽기 전용 설정
              }}
              disabled={disabled}
            />
            <FormSpacer />
            {/* 리뷰 이미지 */}
            <InputLabel>리뷰 이미지</InputLabel>
            <FormSpacer />
            <div className={classes.imageContainer}>
              {data.page?.media && data.page?.media.length > 0 ? (
                data.page.media.slice(0, 10).map((mediaItem, index) => (
                  <img
                    key={index}
                    src={mediaItem?.pageImgUrl ?? "https://via.placeholder.com/150"}
                    alt={`Review Image ${index}`}
                    className={classes.imageItem}
                  />
                ))
              ) : (
                <img
                  src="https://via.placeholder.com/150"
                  alt="Default Review Image"
                  className={classes.imageItem}
                />
              )}
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
};
PageInfo.displayName = "PageInfo";
export default PageInfo;
